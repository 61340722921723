<template>
  <div class="terms-document">
    <h1>개인정보 수집 및 이용 동의</h1>
    <h6>발효일: 2024년 12월 17일</h6>
    <h5>1. 개인정보처리방침의 의의</h5>
    <p>개인정보처리방침은 다음과 같은 중요한 의미를 가지고 있습니다.</p>
    <ul>
      <li>- 자작자작이 어떤 정보를 수집하고, 수집한 정보를 어떻게 사용하며, 필요에 따라 누구와 이를 공유(‘위탁 또는 제공’)하며, 이용목적을 달성한 정보를 언제·어떻게 파기하는지 등 ‘개인정보의 한살이’와 관련한 정보를 투명하게 제공합니다.
      </li>
      <li>- 정보주체로서 이용자는 자신의 개인정보에 대해 어떤 권리를 가지고 있으며, 이를 어떤 방법과 절차로 행사할 수 있는지를 알려드립니다.</li>
      <li>- 개인정보 침해사고가 발생하는 경우, 추가적인 피해를 예방하고 이미 발생한 피해를 복구하기 위해 누구에게 연락하여 어떤 도움을 받을 수 있는지 알려드립니다.</li>
      <li>- 그 무엇보다도, 개인정보와 관련하여 자작자작과 이용자 간의 권리 및 의무 관계를 규정하여 이용자의 ‘개인정보자기결정권’을 보장하는 수단이 됩니다.</li>
    </ul>
    <h5>2. 수집하는 개인정보</h5>
    <p>자작자작 서비스 이용을 위해 필요한 최소한의 개인정보를 수집합니다.</p> 
    <p>회원가입 시점에 자작자작이 이용자로부터 수집하는 개인정보는 아래와 같습니다.</p>
    <ul>
      <li>- 회원가입 시 필수 항목으로 이메일 주소, 비밀번호(암호화), 닉네임, 회원구분을 수집합니다.</li>
      <li>- 비밀번호 없이 회원 가입 시에는 필수항목으로 이메일 주소, 아이디, 닉네임, 회원구분을 수집합니다.</li>
    </ul>
    <p>서비스 이용 과정에서 자작자작이 이용자로부터 수집하는 개인정보는 아래와 같습니다.</p>
    <ul>
      <li>- 회원정보 서비스에서 프로필 사진을 수집할 수 있습니다.</li>
      <li>- 그룹을 개설하여 운영할 경우 학교 또는 기관명, 학년 정보를 수집합니다.</li>
      <li>- 견적신청 서비스를 이용할 경우 성명(이름), 이메일, 소속, 연락처 정보를 수집합니다.</li>
      <li>- 자작자작 내의 서비스 이용, 이벤트 응모 및 경품 신청 과정에서 해당 서비스의 이용자에 한해 추가 개인정보 수집이 발생할 수 있습니다. 추가로 개인정보를 수집할 경우에는 해당 개인정보 수집 시점에서 이용자에게 ‘수집하는 개인정보 항목, 개인정보의 수집 및 이용목적, 개인정보의 보관기간’에 대해 안내 드리고 동의를 받습니다.</li>
    </ul>
    <p>서비스 이용 과정에서 IP 주소, 쿠키, 서비스 이용 기록, 기기정보가 생성되어 수집될 수 있습니다.</p>
    <p>구체적으로 1) 서비스 이용 과정에서 이용자에 관한 정보를 자동화된 방법으로 생성하여 이를 저장(수집)하거나, 2) 이용자 기기의 고유한 정보를 원래의 값을 확인하지 못 하도록 안전하게 변환하여 수집합니다. 이와 같이 수집된 정보는 개인정보와의 연계 여부 등에 따라 개인정보에 해당할 수 있고, 개인정보에 해당하지 않을 수도 있습니다.</p>
    <h6>생성정보 수집에 대한 추가 설명</h6>
    <p>
      <b>IP(Internet Protocol) 주소란?</b><br>
      IP 주소는 인터넷 망 사업자가 인터넷에 접속하는 이용자의 PC 등 기기에 부여하는 온라인 주소 정보입니다.
    </p>
    <p><b>서비스 이용 기록이란?</b><br>
      접속 일시, 이용한 서비스 목록 및 서비스 이용 과정에서 발생하는 정상 또는 비정상 로그 일체, 메일 수 발신 과정에서 기록되는 이메일 주소 등을 의미합니다.
    </p>
    <p><b>기기정보란?</b><br>
      본 개인정보처리방침에 기재된 기기정보는 생산 및 판매 과정에서 기기에 부여된 정보뿐 아니라, 기기의 구동을 위해 사용되는 S/W를 통해 확인 가능한 정보를 모두 일컫습니다. OS(Windows, MAC OS 등) 설치 과정에서 이용자가 PC에 부여하는 컴퓨터의 이름, 설정언어 및 설정 표준시 등을 의미합니다.
    </p>
    <p><b>쿠키(cookie)란?</b><br />
      쿠키는 이용자가 웹사이트를 접속할 때에 해당 웹사이트에서 이용자의 웹 브라우저를 통해 이용자의 PC에 저장하는 매우 작은 크기의 텍스트 파일입니다. 이후 이용자가 다시 웹사이트를 방문할 경우 웹사이트 서버는 이용자 PC에 저장된 쿠키의 내용을 읽어 이용자가 설정한 서비스 이용 환경을 유지하여 편리한 인터넷 서비스 이용을 가능케 합니다. 또한 방문한 서비스 정보, 서비스 접속 시간 및 빈도, 서비스 이용 과정에서 생성된 또는 제공(입력)한 정보 등을 분석하여 특화된 서비스를 제공할 수 있습니다. 이용자는 쿠키에 대한 선택권을 가지고 있으며, 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 다만, 쿠키의 저장을 거부할 경우에는 일부 서비스의 이용에 불편이 있을 수 있습니다.
    </p>
    <h5>3. 수집한 개인정보의 이용</h5>
    <p>자작자작은 회원관리, 서비스 개발·제공 및 향상, 안전한 인터넷 이용환경 구축 등 아래의 목적으로만 개인정보를 이용합니다. 콘텐츠 등 기존 서비스 제공에 더하여, 인구통계학적 분석, 서비스 방문 및 이용기록의 분석, 개인정보 및 관심에 기반한 이용자간 관계의 형성 등에 기반한 신규 서비스 요소의 발굴 및 기존 서비스 개선 등을 위하여 개인정보를 이용합니다. 법령 및 자작자작 이용약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재, 계정도용 및 부정거래 방지, 약관 개정 등의 고지사항 전달, 분쟁조정을 위한 기록 보존, 민원처리 등 이용자 보호 및 서비스 운영을 위하여 개인정보를 이용합니다. 유료 서비스 제공에 따르는 본인인증, 구매 및 요금 결제를 위하여 개인정보를 이용합니다. 이벤트 정보 및 참여기회 제공, 광고성 정보 제공 등 마케팅 및 프로모션 목적으로 개인정보를 이용합니다. 보안, 프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는 서비스 이용환경 구축을 위해 개인정보를 이용합니다.</p>
    <h5>4. 개인정보의 제공 및 위탁</h5>
    <p>자작자작은 이용자의 별도 동의가 있는 경우나 법령에 규정된 경우를 제외하고는 이용자의 개인정보를 절대 제3자에게 제공하지 않습니다.</p>
    <p>자작자작은 이용자가 추후 서비스 이용과정 등에서 따로 동의하는 경우나 법령에 규정된 경우를 제외하고는 이용자의 개인정보를 위에서 말씀드린 목적 범위를 초과하여 이용하거나 제3자에게 제공 또는 공유하지 않습니다. 자작자작 서비스에 제3자의 서비스가 연결되어 제공되는 경우 자작자작은 서비스 이용을 위해 필요한 범위 내에서 이용자의 동의를 얻은 후에 서비스 운영과 개발이나 고객 상담의 목적으로 개인정보를 제3자에게 제공할 수 있습니다. 이용자의 연결 서비스를 위한 개인정보의 제3자 제공 내용은 다음과 같습니다.</p>
    <ol>
      <li>1) 이용자 동의 후 개인정보 제공하는 경우</li>
    </ol>
    <p>이용자의 개인정보는 동의를 받은 개인정보의 수집 및 이용목적이 달성되면 법령 또는 내부방침에 의해 보존할 필요가 있는 경우를 제외하고는 지체 없이 파기됩니다. 이렇게 제공된 개인정보는 해당 서비스 종료 시점까지 보관/이용됩니다.</p>
    <h5>5. 개인정보 자동 수집 장치</h5>
    <p>서비스 이용 지표 분석, 마케팅 성과 측정, 서비스 성능 모니터링 및 개선을 위해 타사 SDK, 플러그인, 쿠키 등(이하 '타사 모듈')을 사용합니다. 자작자작 서비스 방문·이용과 관련한 행태정보가 타사 모듈을 통해 자동 수집되어 IP주소, 광고식별자, 기기식별자, 기기정보, 브라우저 정보, 운영체제 정보, 네트워크 정보 등과 함께 해당 모듈 제공사로 전송될 수 있으나, 원칙적으로 개인정보와 연계하여 전송하지 않습니다. 개인정보와 연계하여 전송하는 경우에는 전송 목적에 따라 동의를 받고 제공하거나 개인정보 위 수탁 문서 등에 의해 제공하며, 타사 모듈을 통해 타사가 처리하는 개인정보에 대해서는 해당 타사의 개인정보 처리방침이 적용됩니다.</p>
    <p>
      Google:
      <a href="https://support.google.com/analytics/answer/6004245" target="_blank">https://support.google.com/analytics/answer/6004245</a
      >
    </p>
    <h5>6. 개인정보의 파기</h5>
    <p>자작자작은 원칙적으로 이용자의 개인정보를 회원 탈퇴 또는 이용목적 달성 시 지체없이 파기하고 있습니다.</p>
    <p>단, 법령에서 일정 기간 정보보관 의무를 부과하는 경우에는 해당 기간 동안 개인정보를 안전하게 보관합니다.</p>
    <p>전자상거래 등에서의 소비자 보호에 관한 법률, 전자문서 및 전자거래 기본법, 통신비밀보호법 등 법령에서 일정기간 정보의 보관을 규정하는 경우는 아래와 같습니다. 자작자작은 이 기간 동안 법령의 규정에 따라 개인정보를 보관하며, 본 정보를 다른 목적으로는 절대 이용하지 않습니다.</p>
    <h6>전자상거래 등에서 소비자 보호에 관한 법률</h6>
    <ul>
      <li>- 계약 또는 청약철회 등에 관한 기록 : 5년 보관</li>
      <li>- 대금결제 및 재화 등의 공급에 관한 기록 : 5년 보관</li>
      <li>- 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 보관</li>
      <li>- 전자문서 및 전자거래 기본법</li>
      <li>- 공인전자주소를 통한 전자문서 유통에 관한 기록 : 10년 보관</li>
      <li>- 통신비밀보호법</li>
      <li>- 로그인 기록 : 3개월</li>
    </ul>
    <p>회원탈퇴, 서비스 종료, 이용자에게 동의 받은 개인정보 보유기간의 도래와 같이 개인정보의 수집 및 이용목적이 달성된 개인정보는 재생이 불가능한 방법으로 파기하고 있습니다. 법령에서 보존의무를 부과한 정보에 대해서도 해당 기간 경과 후 지체없이 재생이 불가능한 방법으로 파기합니다.</p>
    <h5>7. 이용자 및 법정대리인의 권리와 행사 방법</h5>
    <p>이용자는 언제든지 자신의 개인정보를 조회하거나 수정, 삭제할 수 있으며 가입 해지를 진행할 수 있습니다. 보다 구체적으로는 서비스'계정/프로필' 기능을 통한 변경을, 가입 해지(동의 철회)를 위해서는 서비스 내 “회원 탈퇴”를 통해 진행이 가능하며, 이용자가 개인정보의 오류에 대한 정정을 요청한 경우, 정정을 완료하기 전까지 해당 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.</p>
    <p>만14세 미만 이용자의 법정대리인은 특정 절차에 따라 사용자의 활동내용에 대한 조회 및 미성년자의 계정 변경을 요청할 수 있습니다.</p>
    <h5>8. 기타</h5>
    <p>개인정보 취급방침이 변경되는 경우 메일 또는 플랫폼 내 공지사항 등 가용한 수단을 활용하여 별도로 알려 드리겠습니다. 자작자작은 법률이나 자작자작 서비스의 변경사항을 반영하기 위한 목적 등으로 개인정보 취급방침을 수정할 수 있습니다. 개인정보 취급방침이 변경되는 경우 자작자작은 변경 사항을 게시하며, 변경된 개인정보 취급방침은 게시한 날로부터 7일 후부터 효력이 발생합니다. 하지만, 피치 못하게 이용자의 권리에 중요한 변경이 있을 경우 변경될 내용을 7일 전에 미리 알려드리겠습니다.</p>
    <p>자작자작은 항상 열러 있습니다. 이용자가 자작자작 서비스를 이용하면서 발생하는 모든 개인정보보호 관련 문의, 불만, 조언이나 기타 사항은 자작자작의 개인정보보호 담당부서(cs@teampl100.com )로 연락해 주시기 바랍니다.</p>
    <p>자작자작은 이용자의 목소리에 귀 기울이고 신속하고 충분한 답변을 드릴 수 있도록 최선을 다하겠습니다.</p>
  </div>
</template>

<script setup lang="ts">
</script>
<style lang="scss" scoped>
p {
  margin: 8px 0;
}
ul {
  margin: 8px 0;
  li {
    margin: 4px 0px 4px 12px;
  }
}
</style> 