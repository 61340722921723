<template>
  <div class="terms-document">
    <h1>JAJAK 이용 약관</h1>
    <h6>발효일: 2024년 12월 17일</h6>
    <h5>제1조(목적)</h5>
    <p>본 약관은 주식회사 팀플백(이하 ‘회사’라고 함)이 제공하는 JAJAK(자작) 글쓰기 교육 플랫폼 서비스(이하 ’서비스’) 이용과 관련하여 본 서비스를 이용하고자 하는 회원(본 약관에 동의한 자를 말하며 이하 ‘회원’이리 지칭)의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.</p>
    <h5>제2조(약관의 명시, 효력 및 개정)</h5>
    <ol>
      <li>① 회사는 본 약관의 내용을 회원이 알 수 있도록 서비스 페이지(https://www.jajakjajak.com)에 게시함으로 써 이를 공지합니다.</li>
      <li>② 회사는 콘텐츠산업 진흥법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 약관의 규제에 관한 법률, 소비자기본법 등 관련 법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.</li>
      <li>
        ③ 회사가 약관을 개정할 경우에는 개정약관 및 개정약관의 시행일자와 개정사유를 명시하여 시행일자 15일 전부터 시행일 이후 상당한 기간 동안 본 서비스 페이지에 게시하며, 개정된 약관은 그 시행일에 효력이 발생합니다.<br>
        단, 개정 내용이 회원에게 불리한 경우에는 그 시행일자 30일 전부터 시행일 이후 상당한 기간 동안 본 서비스 페이지에 게시하거나 팝업화면을 게시하는 등 이용자가 충분히 인지할 수 있는 합리적으로 가능한 방법으로 공지하며, 개정된 약관은 그 시행일에 효력이 발생합니다.
      </li>
      <li>④ 회사는 제2조 3항에 따라 회원에게 약관 변경에 대해 통지를 하고 공지일로부터 개정약관 시행일까지 회원이 거부의사를 표시하지 아니하면 변경된 약간에 동의한 것으로 간주합니다. 회원이 개정약관에 동의하지 않는 경우 회원은 제14조 제1항의 규정에 따라 이용계약을 해지할 수 있습니다.</li>
    </ol>
    <h5>제3조 (약관 동의 및 서비스 이용계약의 체결)</h5>
    <ol>
      <li>① 회사와 회원 간의 서비스 이용계약은 이용자가 본 약관 내용에 대해 동의하고 서비스 이용신청을 하면 회사가 그 신청을 승낙함으로써 성립합니다.</li>

      <li>② 회사는 이용자가 서비스 회원가입 페이지의 “동의함” 버튼을 클릭하거나 이용자가 본 서비스의 이용을 시작한 경우 본 약관에 동의한 것으로 간주합니다.</li>
      <li>
        ③ 회사는 이용자가 필수사항 등을 성실히 입력하여 서비스 이용신청을 완료하였을 때에는 신청 내용을 확인한 후 지체 없이 이를 승낙하여야 합니다. 단, 회사는 아래 각 호에 해당하는 경우에는 승낙을 유보할 수 있습니다.\
        <ol>
          <li>1. 서비스의 설비용량에 현실적인 여유가 없는 경우</li>
          <li>2. 서비스를 제공하기에는 기술적으로 문제가 있다고 판단되는 경우</li>
        </ol>
      </li>
      <li>
        ④ 회원이 아래 각 호에 해당하는 경우 회사는 본 서비스 이용계약에 대한 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.
        <ol>
          <li>1. 이용자가 서비스 이용신청 시 허위의 정보를 기재하거나 회사가 요청하는 사항을 기재하지 아니한 경우</li>
          <li>2. 제10조 3항에 의해 회사가 본 서비스 이용계약을 해지했던 회원이 다시 서비스 이용신청을 하는 경우. 단, 회사로부터 재가입 승낙을 받은 경우는 예외로 함</li>
          <li>3. 이용자의 귀책사유로 인하여 승낙이 불가능하거나 관련 법령 등에 위반하여 서비스 이용신청을 하는 경우</li>
        </ol>
      </li>
      <li>⑤ 본 조 제3항 및 제4항에 따라 회사가 이용신청에 대해 승낙을 유보하거나 승낙을 하지 않을 경우, 회사는 그 사실을 이용자에게 알리도록 합니다.</li>
      <li>⑥ 본 서비스 이용계약은 회사가 신청절차 상에서 가입 완료를 표시한 시점에 성립합니다.</li>
    </ol>
    <h5>제4조 (개인정보의 보호 및 관리)</h5>
    <ol>
      <li>① 회사는 서비스를 제공하기 위하여 회원으로부터 서비스 이용에 필요한 개인정보를 수집할 수 있습니다.</li>
      <li>② 회사는 관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력하며, 회원의 개인정보의 보호 및 사용에 대해서는 회사가 별도로 고지하는 개인정보 처리방침을 적용합니다.</li>
      <li>③ 회사는 회사의 귀책사유 없이 회원의 귀책사유로 인하여 회원의 정보가 노출된 경우 이에 대해서는 책임을 지지 않습니다.</li>
    </ol>
    <h5>제5조 (서비스의 제공 및 이용)</h5>
    <ol>
      <li>
        ① 회사가 제공하는 서비스의 종류는 아래 각 호로 합니다.
        <ol>
          <li>1. JAJAK 서비스</li>
          <li>2. 상기 1호의 서비스에 부가하여 회사가 회원에게 제공하는 일체의 서비스</li>
        </ol>
      </li>
      <li>② 서비스 이용시간은 회사의 업무상 또는 기술상 불가능한 경우를 제외하고는 연중무휴 1일 24시간(00:00-24:00)으로 함을 원칙으로 합니다. 다만, 회사는 서비스 설비의 정기점검 등의 사유로 일정 기간 동안 서비스 제공을 일시 중지하거나 서비스 제공 시간을 제한할 수 있으며, 이 경우 회사는 회원에 대해 그 사유를 사전에 통지합니다. 단, 회사는 사전 고지가 불가능한 긴급한 사유가 있는 경우 사후에 통지할 수 있습니다.</li>
    </ol>
    <h5>제6조 (서비스의 변경, 중단, 일시 중지)</h5>
    <ol>
      <li>① 회사는 서비스의 일부 또는 전부를 회사의 사업 계획 및 서비스 운영정책에 따라 수정·변경 및 중단할 수 있으며, 이에 대하여 관련 법령에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다.</li>
      <li>② 회사는 서비스용 설비 점검·보수·공사 및 기간통신사업자의 전기통신 서비스의 중지, 서비스 이용의 폭주나 국가비상사태 등을 사유로 서비스 제공에 장애가 발생한 경우 그 사유가 해소될 때까지 서비스를 일시 중지할 수 있습니다.</li>
      <li>③ 회사는 본 조에 따른 서비스의 변경·중단·일시 중지의 사유가 발생한 경우, 서비스를 통해 공지하는 등의 방법으로 회원에게 통지합니다.</li>
    </ol>
    <h5>제7조(채팅 서비스)</h5>
    <ol>
      <li>① 회사는 회원에게 서비스 내 채팅 서비스(이하 '채팅 서비스'라 함)를 제공할 수 있습니다.</li>
      <li>② 회사는 회원이 채팅 서비스를 불법적이거나 타인의 권리를 침해하는 수단으로 이용하거나 청소년 유해매체물, 불법 복제물 등의 정보를 저장, 전송하는 수단으로 사용하는 것을 허락하지 않습니다. 회사는 회원 간에 메신저를 통하여 송수신한 정보를 바탕으로 하여 발생한 손해에 대하여 책임을 부담하지 않습니다.</li>
    </ol>
    <h5>제8조(광고 게재)</h5>
    <ol>
      <li>① 회사는 본 서비스 등을 유지하기 위하여 광고를 게재할 수 있으며, 회원은 서비스 이용 시 노출되는 광고 게재에 대하여 동의합니다.</li>
      <li>② 회사가 제공하는, 제3자가 주체인, 제1항의 광고에 회원이 참여하거나 교신 또는 거래를 함으로써 발생하는 손실과 손해에 대해서는 회사의 고의 또는 중대한 과실이 없는 한 회원의 책임으로 합니다.</li>
    </ol>
    <h5>제9조 (권리의 귀속 및 저작물의 이용)</h5>
    <ol>
      <li>① 회사가 회원에게 제공하는 서비스에 대한 지식재산권을 포함한 일체의 권리는 회사에 귀속됩니다.</li>
      <li>② 회원이 서비스를 이용하는 과정에서 작성한 게시물, 댓글, 태그 등(이하 “게시물 등”이라 합니다)에 대한 저작권을 포함한 일체의 권리는 본 약관조항 또는 당사자 사이 별도의 의사 표시등이 없는 한 각 회원에게 귀속됩니다.</li>
      <li>③ 회원은 본 서비스 이용계약 성립과 동시에 회사가 그 회원이 서비스에 게시한 게시물 등을 국내·외에서 다음 각 호의 목적으로 사용하는 것에 대하여 이를 허락한 것으로 간주합니다.
        <ol>
          <li>1. 서비스(서비스가 제3자가 운영하는 사이트 또는 미디어의 일정 영역 내에 입점하는 형태로 제공되는 경우 포함) 내에서 게시물 등의 복제·전송·전시 및 우수 게시물을 서비스 화면에 노출하기 위하여 게시물의 내용 변경 없이 크기를 변환하거나 단순화하는 등의 방식으로 수정하는 것</li>
          <li>2. 서비스에서 게시물을 복제·전송 또는 전시하는 것. 다만, 회원이 명시적으로 복제·전송 또는 전시에 동의하지 아니한 경우에는 그러하지 않음</li>
          <li>3. 회사의 서비스를 홍보하기 위한 목적으로 미디어·통신사 등에 게시물의 전부 또는 일부를 보도 및 송출할 수 있으며, 이 경우 회사는 회원의 개별 동의 없이 미디어·통신사 등에게 회원정보를 제공하지 않음</li>
          <li>4. 모든 게시물 및 개인 활동 데이터는 연구개발을 위한 기반 자료로 활용될 수 있음</li>
          <li>5. 본 약관에 동의하고 제출되는('작성되는', '업로드되는'과 같이 포괄적 개념으로 사용)모든 회원의 콘텐츠는 로열티가 없으며 해당 콘텐츠를 회사가 이용, 수정, 각색, 복제, 번역, 편곡, 변형, 각색, 영상제작 그 밖의 방법으로 2차적 저작물 작성시 2차 저작물의 이용, 수정, 각색, 복제, 출판, 전송 배포할 수 있는 저작권이 영구적으로 회사에게 귀속됨</li>
          <li>6. 회사가 회원의 콘텐츠를 이용하여 2차적 저작물 작성시 그 2차적 저작물의 이용, 수정, 각색, 복제, 출판, 전송, 배포 공연, 공중 송신, 전시, 대여, 2차적 저작물 작성 등을 할 수 있는 저작권이 영구적으로 회사에게 귀속됨</li>
        </ol>
      </li>
      <li>④ 회사는 전항 이외의 방법으로 회원의 게시물 등을 이용하고자 하는 경우에는 사전에 회원의 동의를 얻습니다.</li>
      <li>⑤ 회원이 서비스에 게시물을 게재하는 것은 다른 회원이 게시물을 서비스 내에서 사용하거나 회사가 검색결과로 사용하는 것을 허락한 것으로 봅니다. 다만, 회원은 서비스 내의 관리기능을 통하여 게시물의 공개옵션 설정 등의 조치를 취할 수 있습니다.</li>
      <li>⑥ 회사는 서비스의 정책 또는 회사가 운영하는 서비스 간 통합 등의 사유로 게시물의 게재위치를 변경·이전할 수 있으며, 이 경우 사전에 공지합니다.</li>
    </ol>
    <h5>제10조 (게시물의 관리 및 임시조치)</h5>
    <ol>
      <li>① 회원의 게시물 등이 관련 법령에 위반되는 내용을 포함하는 경우, 회사는 해당 관련 법령이나 적법한 권리자의 요청에 따라 해당 게시물 등에 대한 게시중단 및 삭제 등의 조치를 취할 수 있습니다.</li>
      <li>② 회사는 게시물 등에 회사의 서비스 운영정책에 위반되는 사유가 있는 것으로 확인된 경우, 운영정책에 따라 해당 게시물에 대해 조치를 취할 수 있습니다.</li>
      <li>③ 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 “정보통신망법”이라 합니다)의 규정에 의해 다른 회원의 공개된 게시물 등이 본인의 사생활을 침해하거나 명예를 훼손하는 등 권리를 침해 받은 회원 또는 제3자(이하 “삭제 등 신청인”이라 합니다)는 그 침해사실을 소명하여 회사에 해당 게시물 등의 삭제 또는 반박 내용의 게재를 요청할 수 있습니다. 이 경우 회사는 해당 게시물 등의 권리 침해 여부를 판단할 수 없거나 당사자 간의 다툼이 예상되는 경우 해당 게시물 등에 대한 접근을 임시적으로 차단하는 조치(이하 “임시조치”라 합니다)를 최장 30일까지 취합니다.</li>
      <li>④ 전항에 의해 본인의 게시물 등이 임시 조치된 회원(이하 “게시자”라 합니다)은 임시조치기간 중 회사에 해당 게시물 등을 복원해 줄 것을 요청(이하 “재 게시 청구”라 합니다)할 수 있으며, 회사는 임시 조치된 게시물의 명예훼손 등 판단에 대한 방송통신심의위원회 심의 요청에 대한 게시자 및 삭제 등 신청인의 동의가 있는 경우 게시 및 삭제 등 신청인을 대리하여 이를 요청하고 동의가 없는 경우 회사가 이를 판단하여 게시물 등의 복원 여부를 결정합니다. 게시자의 재 게시 청구가 있는 경우 임시조치 기간 내에 방송통신심의위원회 또는 회사의 결정이 있으면 그 결정에 따르고 그 결정이 임시조치 기간 내에 있지 않는 경우 해당 게시물 등은 임시조치 만료일 이후 복원됩니다. 재 게시 청구가 없는 경우 해당 게시물 등은 임시조치 기간 만료 이후 삭제됩니다.</li>
      <li>⑤ 회사는 서비스 내에 게시된 게시물 등이 사생활 침해 또는 명예훼손 등 제3자의 권리를 침해한다고 인정하는 경우 회원 또는 제3자의 신고가 없는 경우에도 임시조치(이하 “임의의 임시조치”라 합니다)를 취할 수 있습니다. 임의의 임시 조치된 게시물의 처리 절차는 본 조 제3항 후단 및 제4항의 규정에 따릅니다.</li>
      <li>⑥ 회원의 게시물 등으로 인한 법률상 이익 침해를 근거로, 다른 회원 또는 제3자가 회원 또는 회사를 대상으로 하여 민·형사상의 법적 조치(예: 형사고소, 가처분 신청∙손해배상청구 등 민사소송의 제기)를 취하는 경우, 회사는 동 법적 조치의 결과인 법원의 확정판결이 있을 때까지 관련 게시물 등에 대한 접근을 잠정적으로 제한할 수 있습니다. 게시물 등의 접근 제한과 관련한 법적 조치의 소명, 법원의 확정 판결에 대한 소명 책임은 게시물 등에 대한 조치를 요청하는 자가 부담합니다.</li>
    </ol>
    <h5>제11조 (회사의 의무)</h5>
    <ol>
      <li>① 회사는 안정적인 서비스 제공을 위해 이에 필요한 설비를 유지·점검하며 문제가 발생한 경우 복구 등의 조치를 이행합니다.</li>
      <li>② 회사는 회원의 개인정보를 본인의 승낙 없이 타인에게 누설, 배포하지 않습니다. 단, 전기통신관련법령 등 관계법령에 의하여 관련 국가기관 등의 요구가 있는 경우에는 그러하지 아니합니다.</li>
      <li>③ 회사는 회원의 이메일로 회원이 홍보성 정보 수신에 동의하지 아니한 영리 목적의 광고성 전자우편을 발송하지 아니합니다.</li>
    </ol>
    <h5>제12조 (회원의 의무)</h5>
    <ol>
      <li>① 회사가 회원에게 제공하는 서비스에 대한 지식재산권을 포함한 일체의 권리는 회사에 귀속됩니다.</li>
      <li>② 회원은 아래 각 호에 해당하는 행위를 하여서는 안됩니다.
        <ol>
          <li>1. 회원정보에 다른 사람의 정보를 사용하거나, 허위 사실을 기재하는 행위</li>
          <li>2. 회사의 서비스에 게시된 정보를 변경하거나 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 영리 또는 비영리의 목적으로 복제·출판·방송 등에 사용하거나 제3자에게 제공하는 행위</li>
          <li>3. 회사가 제공하는 서비스를 이용하여 제3자에게 본인을 홍보할 기회를 제공하거나 제3자의 홍보를 대행하는 등의 방법으로 금전을 수수하거나 서비스를 이용할 권리를 양도하고 이를 대가로 금전을 수수하는 행위</li>
          <li>4. 회사 기타 제3자의 명예를 훼손하거나 지식재산권을 침해하는 등 회사나 제3자의 권리를 침해하는 행위</li>
          <li>5. 다른 회원의 계정을 도용하여 부당하게 서비스를 이용한 경우</li>
          <li>6. 외설 또는 폭력적인 메시지·화상·음성 등이 담긴 내용을 게시하거나 공공질서 또는 공서양속에 반하는 정보를 공개 또는 게시하는 행위</li>
          <li>7. 정보통신망법 등 관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램 등)를 전송·게시하거나 청소년보호법에서 규정하는 청소년유해매체물을 게시하는 행위</li>
          <li>8. 회사의 직원이나 서비스의 관리자를 가장하거나 사칭하여 또는 타인의 명의를 도용하여 게시물 등을 작성하거나 이메일을 발송하는 행위</li>
          <li>9. 컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해·파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드·파일·프로그램을 포함하고 있는 자료를 게시하거나 다른 회원에게 발송하는 행위</li>
          <li>10. 스토킹(stalking). 스팸 성 댓글의 게재 등 다른 회원의 정상적인 서비스 이용을 방해하는 행위</li>
          <li>11. 다른 회원의 개인정보를 그 동의 없이 수집·저장·공개하는 행위</li>
          <li>12. 광고 또는 선전 등 영리 목적으로 서비스를 이용하는 행위</li>
          <li>13. 회사가 제공하는 소프트웨어 등을 개작하거나 리버스 엔지니어링, 디컴파일, 디스어셈블 하는 행위</li>
          <li>14. 본 약관 및 회사가 규정한 서비스 운영정책을 위반하는 행위</li>
        </ol>
      </li>
      <li>③ 회사는 회원이 전항 각 호의 행위를 하는 경우 해당 게시물 등을 삭제 또는 임시 조치할 수 있고 회원의 서비스 이용을 제한하거나 그 사유가 중대한 경우 일방적으로 본 계약을 해지할 수 있습니다.</li>
      <li>④ 회원이 본 조 제2항 각 호의 행위를 함으로써 회사에 손해가 발생한 경우, 회사는 해당 회원에 대해 손해배상을 청구할 수 있습니다.</li>
      <li>⑤ 본 서비스 내에서 회사의 관여 없이 회원 간 이루어지는 일체의 행위(거래 행위 포함)와 관련하여 발생하는 모든 의무와 책임은 해당 회원에게 있으며, 회사는 그 내용에 대하여 책임을 지지 않습니다.</li>
    </ol>
    <h5>제13조 (서비스 이용의 제한 및 중지)</h5>
    <ol>
      <li>① 회사는 아래 각 호에 해당하는 사유가 발생한 경우에는 회원의 서비스 이용을 제한하거나 중지시킬 수 있습니다.
        <ol>
          <li>1. 회원이 회사의 서비스 운영을 고의 또는 과실로 방해하는 경우</li>
          <li>2. 회원이 제12조의 의무를 위반한 경우</li>
          <li>3. 기타 중대한 사유로 인하여 회사가 해당 회원에 대해 서비스 제공을 지속하는 것이 상당하지 않다고 판단되는 경우</li>
        </ol>
      </li>
      <li>② 회사는 전항의 규정에 의하여 서비스의 이용을 제한하거나 중지한 때에는 그 사유 및 제한 기간 등을 회원에게 알립니다.</li>
    </ol>
    <h5>제14조 (계정의 관리책임)</h5>
    <p>회원은 본인의 계정 관리에 대한 책임을 부담하며, 본인 계정을 제3자가 무단 이용하는 등 회원의 귀책사유로 인해 발생하는 모든 불이익에 대한 책임을 부담합니다. 단, 회사의 고의∙과실로 인하여 야기된 경우에는 회사가 책임을 부담합니다.</p>
    <h5>제15조 (양도금지)</h5>
    <p>회원의 서비스 받을 권리는 이를 양도 내지 증여의 대상으로 하거나 질권의 목적으로 활용할 수 없습니다.</p>
    <h5>제16조 (이용계약의 해지)</h5>
    <ol>
      <li>① 회원이 본 서비스 이용계약을 해지하고자 하는 때에는 회사가 제공하는 서비스 내의 회원탈퇴 기능을 이용하여 탈퇴를 요청할 수 있습니다. 회사는 탈퇴를 요청한 시점에서 불가피한 사정이 없는 한 즉시 탈퇴요청을 처리합니다.</li>
      <li>② 본 이용 계약이 해지된 경우 회원의 게시물 등은 삭제됩니다. 단, 다른 회원의 게시물에 작성된 댓글 등 다른 회원의 정상적인 서비스 이용에 필요한 게시물 등은 삭제되지 않습니다.</li>
    </ol>
    <h5>제17조(손해배상)</h5>
    <ol>
      <li>① 회원이 본 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하게 되는 경우, 위반한 회원은 회사에 발생하는 손해를 배상하여야 합니다. 다만 회사에게 귀책사유가 있는 부분은 회사가 책임을 부담합니다.</li>
      <li>② 회원이 서비스를 이용함에 있어 저작권 침해와 같은 불법행위나 본 약관 위반 행위로 인하여 회사가 당해 회원 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한- 각종 이의 제기를 받는 경우 당해 회원은 자신의 책임과 비용으로 회사를 면책시켜야 하며, 회사가 면책되지 못한 경우 당해 회원은 그로 인하여 회사에 발생한 손해를 배상하여야 합니다. 단 회사에게 귀책사유가 있는 부분은 회사가 책임을 부담합니다.</li>
      <li>③ 회사는 무료로 제공하는 서비스와 관련하여 발생하는 사항에 대하여는 어떠한 손해도 책임을 지지 않습니다. 단, 회사의 고의나 중과실로 인한 손해인 경우는 예외로 합니다.</li>
    </ol>
    <h5>제18조 (면책사항)</h5>
    <ol>
      <li>① 회사는 다음 각 호의 사유로 서비스를 제공할 수 없는 경우 이로 인하여 회원에게 발생한 손해에 대해서는 책임을 부담하지 않습니다.
        <ol>
          <li>1. 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우</li>
          <li>2. 서비스 제공을 위하여 회사와 서비스 제휴계약을 체결한 제3자의 고의적인 서비스 방해가 있는 경우</li>
          <li>3. 회원의 귀책사유로 서비스의 중지 또는 이용 장애가 발생한 경우</li>
          <li>4. 제1호 내지 제3호를 제외한 기타 회사의 고의∙과실이 없는 사유로 서비스를 제공하는 것이 어려운 경우</li>
        </ol>
      </li>
      <li>② 회사는 회원이 작성하여 서비스에 게재된 정보 및 자료의 신뢰도나 정확성 등에 대해서는 보증을 하지 않으며 이로 인해 발생한 회원의 손해에 대하여는 책임을 부담하지 아니합니다.</li>
      <li>③ 회사는 이용자 상호간 또는 이용자와 제3자간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며 이로 인한 손해를 배상할 책임을 지지 않습니다.</li>
      <li>④ 회사는 회원의 컴퓨터 오류에 의한 손해가 발생한 경우 또는 신상정보 및 전자우편주소를 부정확하게 기재하거나 미 기재하여 손해가 발생한 경우에 대하여 책임을 부담하지 않습니다.</li>
    </ol>
    <h5>제19조 (회원에 대한 통지)</h5>
    <ol>
      <li>① 회사가 회원에 대한 통지를 하는 경우, 본 약관에 별도 규정이 없는 한 회원이 회사에 제공한 이메일 주소로 통지할 수 있습니다.</li>
      <li>② 회사가 회원 전체에게 통지를 하는 경우, 7일 이상 회사의 서비스 페이지 또는 서비스 내 공지사항 등에 게시함으로써 전항의 통지에 갈음할 수 있습니다.</li>
    </ol>
    <h5>제20조 (약관의 해석)</h5>
    <p>본 약관에 명시되지 않은 사항에 대해서는 회사와 회원 간 합의하여 결정하고, 합의가 되지 않는 경우 관련 법령 또는 상관례에 따릅니다.</p>
    <h5>제21조(이의신청 절차)</h5>
    <p>회원은 서비스를 이용함에 있어 발생한 사안에 대한 이의 또는 본 약관과 관련한 이의를 회사가 마련하는 고객센터에 할 수 있으며, 회사는 고객의 이의가 정당하다고 인정되는 때에는 그에 상응하는 조치를 취합니다.</p>
    <h5>제22조 (준거법 및 관할법원)</h5>
    <ol>
      <li>① 본 약관은 대한민국법에 의하여 규정되고 이행되며, 회사와 회원 간에 제기된 소송에는 대한민국법을 적용합니다.</li>
      <li>② 서비스 이용과 관련하여 회사와 회원 간에 이견 또는 분쟁이 있는 경우, 양 당사자 간의 합의에 의해 원만히 해결하여야 합니다. 만약 분쟁이 원만히 해결되지 못하여 소송이 제기된 경우, 소송은 관련 법령에 정한 절차에 따른 법원을 관할법원으로 합니다.</li>
    </ol>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped>
/* 최상위 ol 초기화 */
ol {
  list-style: none; /* 기본 블릿 제거 */
  counter-reset: top-level-counter; /* 최상위 카운터 초기화 */
  padding-left: 0px; /* 들여쓰기 */
}

/* 최상위 li 스타일 */
ol > li {
  position: relative; /* ::before 위치 조정을 위한 상대 위치 */
  list-style: none; /* 기본 블릿 제거 */
  padding-left: 0px; /* 블릿과 텍스트 간격 */
}

/* 하위 ol 초기화 */
ol > li > ol {
  counter-reset: sub-level-counter; /* 하위 카운터 초기화 */
  padding-left: 0px; /* 들여쓰기 */
}

/* 하위 li 스타일 */
ol > li > ol > li {
  position: relative;
  padding-left: 16px; /* 블릿과 텍스트 간격 */
}

ol > li > ol > li::before {
  counter-increment: sub-level-counter; /* 하위 카운터 증가 */
  position: absolute;
  left: 0; /* 블릿 위치 조정 */
}
</style>